<template>
  <div>
    <div class="uk-text-large uk-margin-top">Cotizaciones</div>
    <div v-if="!isLoading">
      <table
        class="
          uk-table uk-table-divider uk-table-hover uk-table-middle uk-visible@s
        "
        v-if="quotes.length !== 0"
      >
        <thead>
          <th width="20%">Fecha de cotización</th>
          <th width="20%">Usuario</th>
          <th class="uk-text-center" width="20%">Código de cotización</th>
          <th class="uk-text-center" width="10%">Cantidad de productos</th>
          <th width="10%" class="uk-text-center">Descargar</th>
          <th width="10%" class="uk-text-center">Editar</th>
          <th width="10%" class="uk-text-center">Estado de cotización</th>
        </thead>
        <tbody>
          <tr v-for="quote in quotes" :key="quote.id">
            <td>
              <div>{{ formatDatetime(quote.created_at) }}</div>
              <div>(Expira el {{ formatDate(quote.expires_at) }})</div>
            </td>
            <td>
              <div>{{ quote.user.first_name }} {{ quote.user.last_name }}</div>
              <div v-if="quote.user.business">
                Empresa: {{ quote.user.business.name }}
              </div>
            </td>

            <td class="uk-text-center">{{ quote.code }}</td>
            <td class="uk-text-center">{{ quote.quotables.length }}</td>
            <td class="uk-text-center">
              <a
                v-if="quote.file"
                :href="quote.file.path"
                target="_blank"
                class="uk-button uk-button-primary uk-border-rounded"
              >
                <span uk-icon="cloud-download"></span>
              </a>
            </td>
            <td class="uk-text-center">
              <router-link
                :to="'/quotes/review/' + quote.id"
                class="uk-button uk-button-primary uk-border-rounded"
              >
                <span uk-icon="pencil"></span>
              </router-link>
            </td>
            <td class="uk-text-center">
              <router-link
                :to="'/quotes/resolution/' + quote.id"
                class="uk-button uk-button-primary uk-border-rounded"
              >
                <span uk-icon="play"></span>
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="uk-text-center">No se han realizado cotizaciones</div>
    </div>
    <div v-else class="uk-text-center">
      <div uk-spinner></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuotesIndex",

  data() {
    return {
      isLoading: true,
      quotes: null,
    };
  },

  mounted() {
    this.fetchQuotes();
  },

  methods: {
    fetchQuotes() {
      this.isLoading = true;
      this.axios
        .get("/quotes/review")
        .then(({ data: { quotes } }) => {
          this.quotes = quotes;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
